import gql from 'graphql-tag'
import {buildQuery} from 'axios-graphql-builder'

export const getEntitiesQuery = screenType => {
	return screenType === 'repo'
		? repoEntityAmountQuery
		: screenType === 'sbl'
		? sblEntityAmountQuery
		: dashboardEntityAmountQuery
}

const portfolio_entries_fragment = gql`
	fragment DasboardPortfolioEntries on PortfolioEntry {
		actions
		activityType
		allInRate
		assetClassification
		assetMaturityDate
		assetSubType
		assetType
		availableQuantity
		balanceSheetContribution {
			amount
			currency
		}
		baseNotional {
			amount
			currency
		}
		basePrice {
			amount
			currency
		}
		bbId
		book
		borrowFee
		loanFee
		buySellInd
		collateralType
		collateral {
			amount
			currency
		}
		counterParty
		createdBy
		createdDate
		cusip
		effectiveBaseNotional {
			amount
			currency
		}
		endDate
		exchangeRate
		fitchRating
		fund
		haircut
		hqla
		id
		inefficiencies {
			inefficiency
			abbr
			displayName
		}
		index
		isin
		legalEntity
		maturityDate
		maturityType
		moodyRating
		needQuantity
		notional {
			amount
			currency
		}
		originalTradeType
		poolFactor
		portfolioEntryType
		partial
		price {
			amount
			currency
		}
		priceMultiplier
		quantity
		recallInefficiencyContribution {
			amount
			currency
		}
		recallQuantity
		referenceRate
		returnInefficiencyContribution {
			amount
			currency
		}
		returnQuantity
		availabilitiesContribution {
			amount
			currency
		}
		needsContribution {
			amount
			currency
		}
		platformTradeId
		rebate
		ric
		sector
		securityId
		securityIdType
		settlementDate
		snpRating
		sourceSystem
		sourceSystemTradeId
		spread
		startDate
		tenor
		termDate
		tradeDate
		ticker
		tradeType
	}
`

const dashboardEntityAmountQuery = gql`
	query {
		portfolio {
			overView {
				matureTrades {
					amount
					currency
				}
				openTrades {
					amount
					currency
				}

				availabilities {
					amount
					currency
				}
				needs {
					amount
					currency
				}
				recallInefficiencies {
					amount
					currency
				}
				returnInefficiencies {
					amount
					currency
				}
				longTrades {
					amount
					currency
				}
				shortTrades {
					amount
					currency
				}
				nonCashLoan {
					amount
					currency
				}
				nonCashBorrow {
					amount
					currency
				}
			}
		}
	}
`

export const sblEntityAmountQuery = gql`
	query {
		sblDashboard {
			overview {
				loans {
					amount
					currency
				}
				borrows {
					amount
					currency
				}
				borrowMatureTrades {
					amount
					currency
				}
				loanMatureTrades {
					amount
					currency
				}
				borrowOpenTrades {
					amount
					currency
				}
				loanOpenTrades {
					amount
					currency
				}
				borrowReRate {
					amount
					currency
				}
				loanReRate {
					amount
					currency
				}

				recallInefficiencies {
					amount
					currency
				}
				returnInefficiencies {
					amount
					currency
				}
				nonCashLoan {
					amount
					currency
				}
				nonCashBorrow {
					amount
					currency
				}
				needs {
					amount
					currency
				}
				availabilities {
					amount
					currency
				}
			}
		}
	}
`

export const repoEntityAmountQuery = gql`
	query {
		repoDashboard {
			repoOverview {
				reRate {
					amount
					currency
				}
				matureTrades {
					amount
					currency
				}
				openTrades {
					amount
					currency
				}
				repoTrades {
					amount
					currency
				}
				reverseRepoTrades {
					amount
					currency
				}
				needs {
					amount
					currency
				}
				availabilities {
					amount
					currency
				}
			}
		}
	}
`

const borrowOpenTradesDataQuery = gql`
	query {
		sblDashboard {
			borrowOpenTrades {
				entries {
					contractValue {
						amount
						currency
					}
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const loanOpenTradesDataQuery = gql`
	query {
		sblDashboard {
			loanOpenTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const recallInefficienciesDataQuery = gql`
	query {
		portfolio {
			recallInefficiencies {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const returnInefficienciesDataQuery = gql`
	query {
		portfolio {
			returnInefficiencies {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const nonCashBorrowDataQuery = gql`
	query {
		portfolio {
			nonCashTrades {
				counterParty
				borrow {
					amount
					currency
				}
				loan {
					amount
					currency
				}
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`
const nonCashLoanDataQuery = nonCashBorrowDataQuery

const matureTradesDataQuery = gql`
	query {
		portfolio {
			matureTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const openTradesDataQuery = gql`
	query {
		portfolio {
			openTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const longTradesDataQuery = gql`
	query {
		portfolio {
			longTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const shortTradesDataQuery = gql`
	query {
		portfolio {
			shortTrades {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const availabilitiesDataQuery = gql`
	query {
		portfolio {
			availabilities {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const needsDataQuery = gql`
	query {
		portfolio {
			needs {
				entries {
					...DasboardPortfolioEntries
				}
			}
		}
	}
	${portfolio_entries_fragment}
`

const intraDayActivitiesQuery = gql`
	query {
		portfolio {
			intraDayActivities {
				counterParty
				activityType
				tradeType
				securityId
				quantity
				price {
					amount
					currency
				}
				basePrice {
					amount
					currency
				}
				baseNotional {
					amount
					currency
				}
				createdBy
				createdDate
			}
		}
	}
`

export const tradingQueueSummaryQuery = (cptyName, cptyType) => {
	const counterParties = cptyName ? '"'.concat(cptyName.join('","'), '"') : ''
	return gql`
		query {
			tradingQueueSummary(tradingQueueSummaryRequest: {counterParties:[${counterParties}], type: "${cptyType}"}) {
				counterParty
				userSummaries {
					userEmail
					entriesCount
				}
			}
		}
	`
}

export const tradeDetailsEntries = {
	actions: null,
	activityType: null,
	allInRate: null,
	assetMaturityDate: null,
	assetSubType: null,
	assetType: null,
	availabilitiesContribution: {
		amount: null,
	},
	balanceSheetContribution: {
		amount: null,
		currency: null,
	},
	contractValue: {
		amount: null,
		currency: null,
	},
	baseNotional: {
		amount: null,
		currency: null,
	},
	bbId: null,
	book: null,
	borrowFee: null,
	buySellInd: null,
	cash: {
		amount: null,
		currency: null,
	},
	collateral: {
		amount: null,
		currency: null,
	},
	collateralType: null,
	counterParty: null,
	createdBy: null,
	createdDate: null,
	cusip: null,
	endDate: null,
	exchangeRate: null,
	fitchRating: null,
	fund: null,
	// fxRate: null,
	haircut: null,
	hqla: null,
	id: null,
	inefficiencies: {
		inefficiency: null,
		abbr: null,
		displayName: null,
	},
	index: null,
	isin: null,
	isRestricted: null,
	legalEntity: null,
	loanFee: null,
	maturityDate: null,
	maturityType: null,
	moodyRating: null,
	needsContribution: {
		amount: null,
	},
	notional: {
		amount: null,
		currency: null,
	},
	originalTradeType: null,
	partial: null,
	poolFactor: null,
	portfolioEntryType: null,
	portfolioEntryTypeOption: null,
	price: {
		amount: null,
		currency: null,
	},
	priceMultiplier: null,
	quantity: null,
	rebate: null,
	recallInefficiencyContribution: {
		amount: null,
	},
	referenceRate: null,
	returnInefficiencyContribution: {
		amount: null,
	},
	ric: null,
	sector: null,
	securityId: null,
	securityIdType: null,
	settlementDate: null,
	snpRating: null,
	sourceSystem: null,
	sourceSystemTradeId: null,
	spread: null,
	startDate: null,
	tenor: null,
	termDate: null,
	ticker: null,
	tradeDate: null,
	tradeType: null,
}
const all = {
	query: {
		portfolio: {
			all: {
				entries: tradeDetailsEntries,
			},
			longTrades: {
				entries: tradeDetailsEntries,
			},
			shortTrades: {
				entries: tradeDetailsEntries,
			},
		},
	},
}

const sblTradesSblDataQuery = gql`
	query {
		sblDashboard {
			sblTrades {
				cashDetails {
					grossExposure {
						amount
						currency
					}
					netExposure {
						amount
						currency
					}
					allBorrows {
						amount
						currency
					}
					allLoans {
						amount
						currency
					}
				}
				nonCashDetails {
					grossExposure {
						amount
						currency
					}
					netExposure {
						amount
						currency
					}
					allBorrows {
						amount
						currency
					}
					allLoans {
						amount
						currency
					}
				}
				grossDetails {
					grossExposure {
						amount
						currency
					}
					netExposure {
						amount
						currency
					}
					allBorrows {
						amount
						currency
					}
					allLoans {
						amount
						currency
					}
				}
				cash {
					counterparty
					loan {
						amount
						currency
					}
					borrow {
						amount
						currency
					}
					trades {
						...DasboardPortfolioEntries
					}
				}
				nonCash {
					counterparty
					loan {
						amount
						currency
					}
					borrow {
						amount
						currency
					}
					trades {
						contractValue {
							amount
							currency
						}
						...DasboardPortfolioEntries
					}
				}
				gross {
					counterparty
					loan {
						amount
						currency
					}
					borrow {
						amount
						currency
					}
					trades {
						contractValue {
							amount
							currency
						}
						...DasboardPortfolioEntries
					}
				}
			}
		}
	}
	${portfolio_entries_fragment}
`
const sblTradesBorrowsDataQuery = sblTradesSblDataQuery
const allTradesQuery = buildQuery(all)
const dashboardPortfolioAnalyticsQuery = {
  recallInefficienciesDataQuery,
	returnInefficienciesDataQuery,
	nonCashBorrowDataQuery,
	nonCashLoanDataQuery,
	sblTradesBorrowsDataQuery,
  sblTradesSblDataQuery,
	matureTradesDataQuery,
	openTradesDataQuery,
	borrowOpenTradesDataQuery,
	loanOpenTradesDataQuery,
	longTradesDataQuery,
	shortTradesDataQuery,
	availabilitiesDataQuery,
	needsDataQuery,
}

export const market_trade_data_fragment = gql`
	fragment marketTradeOrderData on Order {
		isRoll
		createdDate
		createdBy
		createdByEmail
		context {
			applicable
			owner
			displayStatus
		}
		legalId
		creatorLegalId
		state
		agreementType
		bidType
		offerType
		tradeDate
		settlementDate
		maturityDate
		terminationDate
		orderId
		durationInSec
		assetType
		assetSubType
		rateType
		index
		allInRate
		referenceId
		spread
		strategy
		frequencyOfReset
		price {
			amount
			currency
		}
		actions
		responses {
			responseId
			context {
				direction
				displayStatus
			}
			status
			actions
			durationInSec
			allInRate
			offeredPrice {
				amount
				currency
			}
			activityLog {
				status
				context {
					direction
					displayStatus
				}
				price {
					amount
					currency
				}
				allInRate
				durationInSec
			}
		}
	}
`
export const marketTradeDataQuery = gql`
	query {
		orders {
			...marketTradeOrderData
		}
	}
	${market_trade_data_fragment}
`


export {dashboardEntityAmountQuery, dashboardPortfolioAnalyticsQuery, allTradesQuery, intraDayActivitiesQuery}
